.imageCard {
  text-align: center;
}

.imageCard img {
  width: 600px;
}

.backdrop {
  position: fixed;
  background-color: black;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.crop-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 80px;
  z-index: 2;
}

.controls {
  position: fixed;
  bottom: 10px;
  left: 0;
  width: 100%;
  height: 80px;
  z-index: 2;
}

.controls-upper-area {
  text-align: center;
}

.slider {
  width: 50%;
  margin-right: 10px;
}

.button-area {
  text-align: center;
  margin-top: 20px;
}
.button {
  margin-left: 10px;
  margin-right: 10px;
  font-size: 14px;
}
